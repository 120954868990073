import { required } from '@vuelidate/validators'

const Config = {
  datatableFeatures: {
    buttons: {
      createButton: true,
      createButtonRoute: 'administration-plans-new',
      refresh: true,
    },
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'plan_name', text: 'Plan Name' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Plan',
        view: 'View Plan',
        remove: 'Remove Plan',
        create: 'Create New Plan',
      },

      // {
      //   "plan_name": "string",
      //   "plan_charges": 0,
      //   "fpx_charges": 0,
      //   "credit_card_charges": 0,
      //   "payment_form": 0,
      //   "bill_form": 0,
      //   "domain": 0,
      //   "short_url": "string",
      //   "store": 0,
      //   "catalog": 0,
      //   "product": 0,
      //   "api": "string",
      //   "credit_card_enable": true,
      //   "fpx_enable": true,
      //   "ewallet_enable": true,
      //   "bnpl_enable": true,
      //   "created_at": "2022-06-10T19:16:41.889566",
      //   "updated_at": "2022-06-10T19:16:41.889624"
      // }
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'plan_name',
            fieldName: 'Plan Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 6,
            },
          },
          {
            key: 'plan_charges',
            fieldName: 'Plan Charges',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 6,
            },
          },
          {
            key: 'fpx_charges',
            fieldName: 'FPX Charges',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'credit_card_charges',
            fieldName: 'Credit Card Charges',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'payment_form',
            fieldName: 'Payment Form',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'bill_form',
            fieldName: 'Bill Form',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 2,
            },
          },
          {
            key: 'product',
            fieldName: 'Product',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 2,
            },
          },
          {
            key: 'domain',
            fieldName: 'Domain',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 2,
            },
          },
          {
            key: 'store',
            fieldName: 'Store',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'catalog',
            fieldName: 'Catalog',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'api',
            fieldName: 'API',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'short_url',
            fieldName: 'Short URL',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            customValidation: true,
            key: 'credit_card_enable',
            fieldName: 'Enable Credit Card ?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'fpx_enable',
            fieldName: 'Enable FPX?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'ewallet_enable',
            fieldName: 'Enable E-Wallet?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'bnpl_enable',
            fieldName: 'Enable BNPL?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: true, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', pageRoute: 'administration-plans-view-form',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12', pageRoute: 'administration-plans-form',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'plan_name', label: 'Plan Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'plan_charges', label: 'Plan Price', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'fpx_enable', label: 'FPX', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'fpx_charges', label: 'FPX Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'channel',
        },
        {
          key: 'fpx_b2b_charges', label: 'FPX B2B Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'channel',
        },
        {
          key: 'credit_card_enable', label: 'Credit Card', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'credit_card_charges', label: 'Credit Card Charges', sortable: true, visible: false, type: 'percentage', showInDetails: true, group: 'channel',
        },
        {
          key: 'ewallet_enable', label: 'E-Wallet', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'ewallet_charges', label: 'E-Wallet Charges', sortable: true, visible: false, type: 'percentage', showInDetails: true, group: 'channel',
        },
        {
          key: 'bnpl_enable', label: 'BNPL', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'bnpl_charges', label: 'BNPL Charges', sortable: true, visible: false, type: 'percentage', showInDetails: true, group: 'channel',
        },
        {
          key: 'crypto_enable', label: 'Crypto', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'crypto_charges', label: 'Crypto Charges', sortable: true, visible: false, type: 'percentage', showInDetails: true, group: 'channel',
        },
        {
          key: 'paypal_enable', label: 'Paypal', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'paypal_charges', label: 'Paypal Charges', sortable: true, visible: false, type: 'percentage', showInDetails: true, group: 'channel',
        },
        {
          key: 'threshold_charges', label: 'Threshold Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'internalCharges',
        },
        {
          key: 'min_payout_charges', label: 'Min Payout Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'internalCharges',
        },
        {
          key: 'min_fund_charges', label: 'Min Fund Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'internalCharges',
        },
        {
          key: 'max_fund_charges', label: 'Max Fund Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'internalCharges',
        },
        {
          key: 'whatsapp_charges', label: 'Whatsapp Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'internalCharges',
        },
        {
          key: 'sms_charges', label: 'SMS Charges', sortable: true, visible: false, type: 'monetary', showInDetails: true, group: 'internalCharges',
        },
        {
          key: 'bill_form', label: 'Bill Form', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'store', label: 'Store', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'virtual_account', label: 'Virtual Account', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'catalog', label: 'Catalog', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'domain', label: 'Domain', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'short_url', label: 'Short URL', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'api', label: 'API', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'product', label: 'Product', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'record_status', label: 'Record Status', sortable: true, visible: false,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, type: 'date',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
